import React,{useState,useEffect} from "react";
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
import "./Calculator.css";

const Calculator=()=>{

    const [csvData,setCsvData]=useState();
    const [newCsvData,setNewCsvData]=useState([]);
    const [displayCsv,setDisplayCsv]=useState([]);

    const headers = [
        { label: "Produkt", key: "product" },
        { label: "Ean", key: "ean" },
        { label: "Zamawiamy", key: "zamawiamy" },
        { label: "Kod", key: "kod" }
    ];

    const handleForce = (data) => {
        setCsvData(data);
    };

    const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    const Count=(price,sold,margin)=>{
        if(margin>18){
            if(price<30){
                return Math.round(sold/10*10);
            }else if(price<90){
                return Math.round(sold/10*3);
            }else return Math.round(sold/10*1);
        }else return 0;
    }

    useEffect(()=>{
        let data={};
        if(csvData !==undefined) Object.values(csvData).map((item,index)=>
            data[index]={
                produkt:item.produkt,
                ean:item.ean,
                zamawiamy:Count(item.cena,item.sprzedano,item.marza),
                kod:item.kod
            }
        );

        setDisplayCsv(data);
        setNewCsvData(JSON.stringify(data,0,2));
        
    },[csvData]);

    return(
        <>
            <div className="container">
                <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleForce}
                parserOptions={papaparseOptions}
                />

                <div id="download">
                    <CSVLink data={newCsvData} headers={headers}>
                        Download csv
                    </CSVLink>
                </div>

                <div id='wrapper'>
                    {Object.values(displayCsv).map(item=>
                        <div id='item'>
                            <div style={{width:'50%'}}>
                                {item.produkt}
                            </div>
                            <div style={{width:'20%'}}>
                                {item.ean}
                            </div>
                            <div style={{width:'9%'}}>
                                {item.zamawiamy}
                            </div>
                            <div style={{width:'20%'}}>
                                {item.kod}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Calculator;