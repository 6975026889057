import {Calculator} from './components';

function App() {
  return (
    <>
      <Calculator/>
    </>
  );
}

export default App;
